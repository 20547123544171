import { Routes, Route, Navigate } from "react-router-dom";
import DashboardShell from "../layout/dashboard";
import Setting from "../layout/setting";
import Dashboard from "../screens/dashboard";
import BantuanSosial from "../screens/bantuanSosial";
import BeritaPage from "../screens/berita";
import AdministrasiUmum from "../screens/administrasiUmum";
import Kependudukan from "../screens/kependudukan";
import Keuangan from "../screens/keuangan";
import Umkm from "../screens/umkm";
import ProfilDesa from "../screens/profilDesa";
import Wisata from "../screens/wisata";
import LogIn from "../component/Login/LogIn";
import useAuth from "../context/Auth/hooks/useAuth";
import Persuratan from "../screens/persuratan";
import Kios from "../screens/kios";
import Notification from "../layout/notification";
import NotificationDetail from "../layout/notificationDetail";
import Pengaduan from "../screens/pengaduan";

const DashboardRoute = () => {
  const auth = useAuth();

  // Cek apakah pengguna sudah login
  if (!auth.isLogged) {
    return <Navigate to="/login" />;
  }

  return (
    <DashboardShell>
      <Routes>
        {/* Tampilkan dashboard kecamatan jika slugType adalah kecamatan */}
        <Route index element={<Dashboard />} />
        <Route path="pengaturan" element={<Setting />} />
        <Route path="notifikasi" element={<Notification />} />
        <Route
          path="notifikasi/notifikasi-detail"
          element={<NotificationDetail />}
        />
        <Route path="administrasi-umum" element={<AdministrasiUmum />} />
        <Route path="kependudukan" element={<Kependudukan />} />
        <Route path="bantuan-sosial" element={<BantuanSosial />} />
        <Route path="berita" element={<BeritaPage />} />
        <Route path="pembangunan" element={<Keuangan />} />
        <Route path="umkm" element={<Umkm />} />
        <Route path="profil-desa" element={<ProfilDesa />} />
        <Route path="wisata" element={<Wisata />} />
        <Route path="persuratan" element={<Persuratan />} />
        <Route path="kios" element={<Kios />} />
        <Route path="pengaduan" element={<Pengaduan />} />
      </Routes>
    </DashboardShell>
  );
};

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/dashboard/*" element={<DashboardRoute />} />
    </Routes>
  );
};

export default AppRoute;
