import React, { useEffect, useState, Fragment, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { CDN_URL, getSlugType, KODE_SLUG } from "../../utils/api";

export default function BeritaPagination(props) {
  const { list_kecamatan, list_desa } = props.resultData;
  let { list_berita } = props.resultData;

  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  const [selectedKec, setSelectedKec] = useState(
    getSlugType() === "kecamatan"
      ? list_kecamatan.find(
          (k) =>
            k.nama_kecamatan.toLowerCase() ===
            KODE_SLUG.split("-")[0].toLowerCase()
        )?.kode_wilayah || ""
      : ""
  );

  const [selectedDesa, setSelectedDesa] = useState("");
  const [query, setQuery] = useState("");

  // Daftar kecamatan
  const listKec = useMemo(() => {
    return list_kecamatan;
  }, [list_kecamatan]);

  // Filter untuk menampilkan hanya kecamatan sesuai KODE_SLUG
  const filteredKec = useMemo(() => {
    if (getSlugType() === "kecamatan") {
      const kecamatanName = KODE_SLUG.split("-")[0];
      return list_kecamatan.filter(
        (k) => k.nama_kecamatan.toLowerCase() === kecamatanName.toLowerCase()
      );
    }
    return listKec;
  }, [listKec, list_kecamatan]);
  // Daftar desa yang di tampilkan berdasarkan kecamatan yang dipilih
  const listDeskel = useMemo(() => {
    if (!selectedKec) {
      return list_desa; // Jika kecamatan tidak dipilih, tampilkan semua desa
    }
    return list_desa.filter((desa) => {
      let kode_kec = `${desa.k1 || ""}.${
        desa.k2 !== undefined && desa.k2 !== null
          ? desa.k2.toString().padStart(2, "0")
          : ""
      }.${
        desa.k3 !== undefined && desa.k3 !== null
          ? desa.k3.toString().padStart(2, "0")
          : ""
      }`;
      return kode_kec === selectedKec;
    });
  }, [list_desa, selectedKec]);

  // Debugging untuk melihat data kecamatan dan desa
  console.log("Selected Kecamatan:", selectedKec);
  console.log("Selected Desa:", selectedDesa);
  console.log("List Deskel:", listDeskel);
  console.log("List Berita:", list_berita);

  const filteredBerita = useMemo(() => {
    const filtered = list_berita.filter((berita) => {
      let beritaKecKode = `${berita.k1 || ""}.${
        berita.k2 !== undefined && berita.k2 !== null
          ? berita.k2.toString().padStart(2, "0")
          : ""
      }.${
        berita.k3 !== undefined && berita.k3 !== null
          ? berita.k3.toString().padStart(2, "0")
          : ""
      }`;

      // Dapatkan semua kode desa untuk kecamatan yang dipilih
      const selectedDesaCodes = listDeskel.map((desa) => desa.kode_wilayah);

      // Cek jika berita kecamatan kode cocok dengan kode kecamatan yang dipilih
      if (selectedKec) {
        if (selectedDesa && selectedDesa !== "Semua Desa") {
          // Filter berita untuk mencocokkan desa yang dipilih
          return berita.kode_wilayah === selectedDesa;
        } else {
          // Tampilkan berita dari semua desa dalam kecamatan yang dipilih
          return selectedDesaCodes.includes(berita.kode_wilayah);
        }
      } else {
        // Jika kecamatan tidak dipilih, tampilkan semua berita
        return true;
      }
    });

    setPageCount(Math.ceil(filtered.length / itemsPerPage));
    return filtered;
  }, [selectedKec, selectedDesa, list_berita, listDeskel, itemsPerPage]);

  useEffect(() => {
    setItemOffset(0);
  }, [selectedKec, selectedDesa, query, filteredBerita]);

  useEffect(() => {
    setLoading(true);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredBerita.slice(itemOffset, endOffset));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [itemOffset, itemsPerPage, filteredBerita]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col">
          <div className="search-produk">
            <form className="search-form-produk d-flex align-items-center">
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                name="query"
                placeholder="Cari Berita..."
                title="Enter search keyword"
              />
              <button type="submit" title="Search" disabled>
                <i className="bi bi-search"></i>
              </button>
            </form>
          </div>
        </div>
        {/* Tampilkan opsi "Semua Kecamatan" hanya jika tidak dalam mode kecamatan */}
        {getSlugType() !== "kecamatan" && (
          <div className="col">
            <select
              value={selectedKec}
              onChange={(e) => setSelectedKec(e.target.value)}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="">Semua Kecamatan</option>
              {filteredKec.map((item) => (
                <option key={item.kode_wilayah} value={item.kode_wilayah}>
                  {item.nama_kecamatan}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col">
          <select
            value={selectedDesa}
            onChange={(e) => setSelectedDesa(e.target.value)}
            className="form-select "
            aria-label="Default select example "
          >
            <option>Semua Desa</option>
            {listDeskel.map((item) => (
              <option key={item.kode_wilayah} value={item.kode_wilayah}>
                {item.nama_deskel}
              </option>
            ))}
          </select>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredBerita.length === 0 ? (
        <div className="d-flex justify-content-center">
          <p>Tidak ada data ditemukan</p>
        </div>
      ) : (
        <div>
          {currentItems.map((item, key) => (
            <div key={key} className="post-item clearfix">
              <img
                src={`${CDN_URL}uploads/profil/${item.kode_wilayah}/berita/thumbs/${item.foto}`}
                alt="News"
              />
              <h4>
                <a
                  href={`https://${item.slug_desa}.digitaldesa.id/berita/${item.slug}`}
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {item.judul}
                </a>
              </h4>
              <p>{item.isi}</p>
            </div>
          ))}
          <ReactPaginate
            className="pagination justify-content-center"
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            forcePage={itemOffset / itemsPerPage}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </Fragment>
  );
}
