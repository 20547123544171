import React, { useMemo, useState, Fragment } from "react";
import DataTable from "react-data-table-component";
import { rupiah } from "../../utils/helper.min";
import { getSlugType, KODE_SLUG } from "../../utils/api";

const APBDTable = (props) => {
  const { list_kecamatan } = props.resultData;

  // Filter on select kecamatan
  const [selectedKec, setSelectedKec] = useState(
    getSlugType() === "kecamatan"
      ? list_kecamatan.find(
          (k) =>
            k.nama_kecamatan.toLowerCase() ===
            KODE_SLUG.split("-")[0].toLowerCase()
        )?.kode_wilayah || ""
      : ""
  );

  // Memoized filtered kecamatan
  const filteredKec = useMemo(() => {
    if (getSlugType() === "kecamatan") {
      const kecamatanName = KODE_SLUG.split("-")[0];
      return list_kecamatan.filter(
        (k) => k.nama_kecamatan.toLowerCase() === kecamatanName.toLowerCase()
      );
    }
    return list_kecamatan;
  }, [list_kecamatan]);

  const [query, setQuery] = useState("");

  const rows = useMemo(() => {
    let data = list_kecamatan;
    if (selectedKec !== "" && selectedKec !== "0") {
      data = data.filter((item) => {
        let itemKec = `${item.k1}.${item.k2
          .toString()
          .padStart(2, "0")}.${item.k3.toString().padStart(2, "0")}`;

        return itemKec === selectedKec;
      });
    }

    if (query !== "" && query !== "0") {
      data = data.filter((item) => {
        return item.nama_kecamatan.toLowerCase().includes(query);
      });
    }

    return data;
  }, [list_kecamatan, selectedKec, query]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#F1ECFF",
        borderRight: "1px solid #EDEDED",
        borderTop: "1px solid #EDEDED",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        borderRight: "1px solid #EDEDED",
      },
    },
  };

  return (
    <Fragment>
      {/* Tampilkan opsi "Semua Kecamatan" hanya jika tidak dalam mode kecamatan */}
      {getSlugType() !== "kecamatan" && (
        <div className="row g-1 mb-4">
          <div className="col-3">
            <div className="search-produk">
              <form className="search-form-produk d-flex align-items-center">
                <input
                  type="text"
                  name="query"
                  placeholder="Cari Kecamatan..."
                  title="Enter search keyword"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button title="Search" disabled>
                  <i className="bi bi-search"></i>
                </button>
              </form>
            </div>
          </div>

          <div className="col-3">
            <select
              onChange={(e) => setSelectedKec(e.target.value)}
              defaultValue="0"
              className="form-select"
              aria-label="Pilih Kecamatan"
            >
              <option value="">Semua Kecamatan</option>
              {filteredKec.map((item, key) => {
                return (
                  <option key={key} value={item.kode_wilayah}>
                    {item.nama_kecamatan}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      )}
      <DataTable
        columns={[
          {
            name: "No",
            selector: (row, index) => index + 1,
            width: "60px",
            style: {
              borderLeft: "1px solid #EDEDED",
            },
          },
          {
            name: "Kecamatan",
            sortable: true,
            selector: (row) => row.nama_kecamatan,
            wrap: true,
          },
          {
            name: "Anggaran",
            sortable: true,
            selector: (row) => rupiah(row.anggaran_2023),
            wrap: true,
          },
          {
            name: "Realisasi",
            sortable: true,
            selector: (row) => rupiah(row.realisasi_2023),
            wrap: true,
          },
          {
            name: "Sisa",
            sortable: true,
            selector: (row) => rupiah(row.sisa_2023),
            wrap: true,
          },
        ]}
        data={rows}
        customStyles={customStyles}
        pagination
      />
    </Fragment>
  );
};

export default APBDTable;
