import React, { useMemo, useState, Fragment } from "react";
import DataTable from "react-data-table-component";
import { getSlugType, KODE_SLUG } from "../../utils/api";

const ProfilTable = (props) => {
  const { list_kecamatan, list_desa } = props.resultData;

  // Filter on select kecamatan
  const [selectedKec, setSelectedKec] = useState(
    getSlugType() === "kecamatan"
      ? list_kecamatan.find(
          (k) =>
            k.nama_kecamatan.toLowerCase() ===
            KODE_SLUG.split("-")[0].toLowerCase()
        )?.kode_wilayah || ""
      : ""
  );
  const [selectedDesa, setSelectedDesa] = useState("");
  const [query, setQuery] = useState("");

  const listDeskel = useMemo(() => {
    setSelectedDesa("");
    return list_desa.filter((desa) => {
      let kode_kec = `${desa.k1}.${desa.k2
        .toString()
        .padStart(2, "0")}.${desa.k3.toString().padStart(2, "0")}`;
      return kode_kec === selectedKec;
    });
  }, [list_desa, selectedKec]);

  const listKec = useMemo(() => {
    return list_kecamatan;
  }, [list_kecamatan]);

  const filteredKec = useMemo(() => {
    if (getSlugType() === "kecamatan") {
      const kecamatanName = KODE_SLUG.split("-")[0];
      return list_kecamatan.filter(
        (k) => k.nama_kecamatan.toLowerCase() === kecamatanName.toLowerCase()
      );
    }
    return listKec;
  }, [listKec, list_kecamatan]);

  const rows = useMemo(() => {
    const deskel = list_desa.filter((desa) => {
      if (query !== "") {
        if (desa.nama_deskel.toLowerCase().indexOf(query.toLowerCase()) > -1) {
          return true;
        } else {
          return false;
        }
      }
      if (selectedKec && selectedDesa) {
        return desa.kode_wilayah === selectedDesa;
      } else if (selectedKec) {
        let kode_kec = `${desa.k1}.${desa.k2
          .toString()
          .padStart(2, "0")}.${desa.k3.toString().padStart(2, "0")}`;
        return kode_kec === selectedKec;
      } else {
        return true;
      }
    });

    return deskel;
  }, [list_desa, query, selectedKec, selectedDesa]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        backgroundColor: "#F1ECFF",
        borderRight: "1px solid #EDEDED",
        borderTop: "1px solid #EDEDED",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        borderRight: "1px solid #EDEDED",
      },
    },
  };

  return (
    <Fragment>
      <div className="row g-1 my-4">
        <div className="col-3">
          <div className="search-produk">
            <form className="search-form-produk d-flex align-items-center">
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                name="query"
                placeholder="Cari Desa/Kelurahan..."
                title="Enter search keyword"
              />
              <button type="submit" title="Search" disabled>
                <i className="bi bi-search"></i>
              </button>
            </form>
          </div>
        </div>
        {/* Tampilkan opsi "Semua Kecamatan" hanya jika tidak dalam mode kecamatan */}
        {getSlugType() !== "kecamatan" && (
          <div className="col-3">
            <select
              value={selectedKec}
              onChange={(e) => setSelectedKec(e.target.value)}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="">Semua Kecamatan</option>
              {filteredKec.map((item) => {
                return (
                  <option
                    key={item.kode_wilayah}
                    value={item.kode_wilayah}
                    selected={selectedKec === item.kode_wilayah}
                  >
                    {item.nama_kecamatan}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="col-3">
          <select
            value={selectedDesa}
            onChange={(e) => setSelectedDesa(e.target.value)}
            className="form-select"
            aria-label="Default select example"
          >
            <option value={""}>Semua Desa</option>
            {listDeskel.map((item) => {
              return (
                <option
                  key={item.kode_wilayah}
                  value={item.kode_wilayah}
                  selected={selectedDesa === item.kode_wilayah}
                >
                  {item.nama_deskel}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <DataTable
        columns={[
          {
            name: "No",
            selector: (row, index) => index + 1,
            width: "70px",
            style: {
              borderLeft: "1px solid #EDEDED",
            },
          },
          {
            name: "Kode Wilayah",
            sortable: true,
            selector: (row) => row.kode_wilayah,
          },
          {
            name: "Kecamatan",
            sortable: true,
            selector: (row) => row.nama_kecamatan,
          },
          {
            name: "Desa",
            sortable: true,
            selector: (row) => row.nama_deskel,
          },
          {
            name: "Website",
            sortable: true,
            selector: (row) => row.link,
            cell: (row) => {
              return (
                <a
                  href={row.link}
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ color: "#3B2D64" }}
                >
                  <i className="fa-solid fa-earth-asia"></i>
                  &nbsp; Website Desa {row.desa}
                </a>
              );
            },
          },
        ]}
        data={rows}
        customStyles={customStyles}
        pagination
      />
    </Fragment>
  );
};

export default ProfilTable;
